import {Formik} from 'formik';
import React, {useState, useMemo, useRef, useCallback} from 'react';
import {Alert, Col, Container, Row, Spinner} from 'react-bootstrap';
import {getColoredImage} from 'shared/helpers';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';
import {CBCButton, FormControl, CBCListItem, Title} from './StyledElements';
import {Link, useLocation} from 'react-router-dom';
import {DisplayMessage} from 'shared/helpers/AuthHelpers';
import {object, string} from 'yup';
import {SecurePassword} from 'assets';
import {passwordResetRequestAction} from 'components/customer/Auth/store/authSlice';
import {useAppDispatch} from 'store/customer';
import {LoginContentColumn} from 'components/customer/Auth/helper/layout';
import {useTheme} from 'styled-components';
import {useTransition} from 'shared/helpers/useTransition';

interface LocationInterface {
    state?: {
        username?: string;
    };
}

export const PasswordResetRequestForm = (): JSX.Element => {
    const isSmallDevice = isDeviceSmall();
    const location: LocationInterface = useLocation();
    const dispatch = useAppDispatch();

    const formSchema = useRef(
        object().shape({
            email: string()
                .email('You have entered invalid email address')
                .required('Please enter your email'),
        })
    ).current;
    const [error, setError] = useState<string>(null);
    const [message, setMessage] = useState<string>(null);
    const [loading, startTransition] = useTransition();
    const theme = useTheme();

    const email = useRef('');
    if (location?.state?.username) {
        email.current = location.state.username;
    }

    const passwordImage = useMemo<string>(() => {
        const passwordImage = getColoredImage(
            String(SecurePassword),
            '#1392CD',
            theme.colors.primary.main
        );

        return passwordImage;
    }, [theme]);

    const passwordResetRequestHandler = useCallback(
        (values: {email: string}) => {
            startTransition(async () => {
                try {
                    const message = await dispatch(
                        passwordResetRequestAction(values.email)
                    ).unwrap();

                    setError(null);
                    setMessage(message);
                } catch (e) {
                    setError(String(e));
                    throw e;
                }
            });
        },
        []
    );

    return (
        <Formik
            validationSchema={formSchema}
            initialValues={{
                email: email.current,
            }}
            onSubmit={passwordResetRequestHandler}>
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                errors,
                touched,
            }) => (
                <form onSubmit={handleSubmit}>
                    <Container>
                        <Row style={{fontSize: '.9em'}}>
                            <LoginContentColumn>
                                <Title style={{marginTop: 0}}>
                                    <img
                                        src={passwordImage}
                                        style={{marginRight: '15px'}}
                                    />
                                    Reset Password
                                </Title>

                                <div style={{textAlign: 'left'}}>
                                    <strong>
                                        Change your password in three easy
                                        steps.
                                    </strong>
                                </div>

                                <CBCListItem index="01">
                                    Enter your email address below
                                </CBCListItem>
                                <CBCListItem index="02">
                                    Check your inbox for an email containing a
                                    temporary link
                                </CBCListItem>
                                <CBCListItem index="03">
                                    Use the link to reset your password
                                </CBCListItem>
                            </LoginContentColumn>
                        </Row>
                        <Row>
                            <LoginContentColumn>
                                <DisplayMessage
                                    errors={errors}
                                    touched={touched}
                                />

                                {error != null ? (
                                    <Alert
                                        style={{
                                            marginBottom: '15px',
                                            paddingLeft: '15px',
                                            textAlign: 'left',
                                        }}
                                        variant="danger">
                                        {error}
                                    </Alert>
                                ) : null}

                                {message != null ? (
                                    <Alert
                                        style={{
                                            textAlign: 'left',
                                            marginBottom: '15px',
                                            paddingLeft: '15px',
                                        }}
                                        variant="success">
                                        {message}
                                    </Alert>
                                ) : null}
                            </LoginContentColumn>
                        </Row>
                        <Row>
                            <LoginContentColumn>
                                <FormControl
                                    type="text"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values ? values.email : ''}
                                    name="email"
                                    placeholder="Email"
                                />

                                <Row
                                    style={{
                                        marginTop: isSmallDevice
                                            ? '15px'
                                            : '35px',
                                    }}>
                                    <Col
                                        md={{span: 6, order: 1}}
                                        xs={{span: 12, order: 2}}>
                                        <CBCButton as={Link} to="/v2/login">
                                            Back to Login
                                        </CBCButton>
                                    </Col>
                                    <Col
                                        md={{span: 6, order: 2}}
                                        xs={{span: 12, order: 1}}>
                                        <CBCButton
                                            type="submit"
                                            disabled={loading}>
                                            {loading ? (
                                                <Spinner
                                                    style={{
                                                        width: '25px',
                                                        height: '25px',
                                                        marginTop: '5px',
                                                    }}
                                                    animation="border"
                                                    role="status">
                                                    <span className="visually-hidden">
                                                        Loading...
                                                    </span>
                                                </Spinner>
                                            ) : message == null ? (
                                                <>Submit</>
                                            ) : (
                                                <>Resend email</>
                                            )}
                                        </CBCButton>
                                    </Col>
                                </Row>
                            </LoginContentColumn>
                        </Row>
                    </Container>
                </form>
            )}
        </Formik>
    );
};
