import {
    invalidateProductAndCost,
    useUpdateProductQuantityMutation,
} from 'components/customer/Product/store/productApi';
import {useJobContext, useNotificationContext} from 'contexts';
import React, {useCallback, useEffect, useState} from 'react';
import {genericMessageHandler} from 'shared/helpers';
import {PartialJob} from 'shared/types/PartialJob';
import {useAppDispatch} from 'store/customer';

export const useProductQuantity = (
    value: number,
    productDetails: {
        id: number;
    }
) => {
    const dispatch = useAppDispatch();
    const [quantity, setQuantity] = useState(value);
    const [saving, setSaving] = useState(false);
    const {notify} = useNotificationContext();
    const {setIsPageDirty} = useJobContext() as PartialJob;
    const [updateProductQuantity] = useUpdateProductQuantityMutation();

    const updateQuantity = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.value == '') {
                setQuantity(undefined);
            } else {
                let value = parseInt(event.target.value);

                if (value <= 0) value = 1;

                setQuantity(value);
            }
        },
        []
    );

    const decreaseQuantity = useCallback(
        () => quantity >= 2 && setQuantity(quantity - 1),
        [quantity]
    );

    const increaseQuantity = useCallback(
        () => setQuantity(quantity + 1),
        [quantity]
    );

    const updateProduct = useCallback(async () => {
        if (!saving) {
            setSaving(true);
            setIsPageDirty([]);
            try {
                await updateProductQuantity({
                    cabinetId: productDetails.id,
                    quantity,
                });

                dispatch(invalidateProductAndCost());

                genericMessageHandler(
                    notify,
                    {message: 'Product quantity updated'},
                    'success'
                );

                setSaving(false);
            } catch (e) {
                genericMessageHandler(notify, {
                    message:
                        'Product quantity could not be updated. Please try again later.',
                });
                setSaving(false);
            }
        }
    }, [quantity, saving]);

    useEffect(() => {
        if (value != quantity) setQuantity(value);
    }, [value]);

    return {
        quantity,
        decreaseQuantity,
        increaseQuantity,
        updateQuantity,
        updateProduct,
        saving,
    };
};
