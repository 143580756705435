import {
    JobCabinetVariation,
    JobCabinetVariationApi,
} from 'components/customer/Job/entity/JobCabinetVariation';

export const mapVariation = (
    variation: JobCabinetVariationApi
): JobCabinetVariation => {
    return {
        id: parseInt(variation.id),
        room_cab_number: parseInt(variation.room_cab_number),
        room_id: parseInt(variation.room_id),
        variation_cost: parseFloat(variation.variation_cost),
        comment: variation.comment,
        type_name: variation.type_name,
    };
};
