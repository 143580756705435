import React, {useCallback} from 'react';
import {Row} from 'react-bootstrap';
import {DefaultTheme, ThemeProvider} from 'styled-components';
import {
    AngleOverlay,
    LoginBody,
    LoginContent,
    LoginHeader,
    LoginWrapper,
    LogoWrapper,
} from './StyledElements';
import {useAppSelector} from 'store/customer';
import {
    selectLoginLogo,
    selectManufacturerAvailable,
    selectManufacturerName,
} from 'store/customer/brandingSlice';
import {
    useLoginLayoutHelper,
    LoginContentColumn,
} from 'components/customer/Auth/helper/layout';
import {shallowEqual} from 'react-redux';
import {Logo} from 'assets';

export const LoginLayout = ({element}: {element: JSX.Element}): JSX.Element => {
    const logo = useAppSelector(selectLoginLogo, shallowEqual);
    const manufacturerName = useAppSelector(
        selectManufacturerName,
        shallowEqual
    );
    const isManufacturerAvailable = useAppSelector(selectManufacturerAvailable);
    const {centeredLoginThreshold, fullWidthCardThreshold} =
        useLoginLayoutHelper();

    const themeOverride = useCallback(
        (theme: DefaultTheme) => ({
            ...theme,
            device: {
                ...theme.device,
                isSmallDevice: fullWidthCardThreshold,
                centeredLoginThreshold,
            },
        }),
        [centeredLoginThreshold, fullWidthCardThreshold]
    );

    return (
        <ThemeProvider theme={themeOverride}>
            <LoginHeader />
            <LoginBody>
                <AngleOverlay>
                    <LoginWrapper>
                        <LoginContent>
                            <LogoWrapper>
                                <img
                                    src={
                                        logo ||
                                        `data:image/svg+xml;base64, ${btoa(
                                            String(Logo)
                                        )}`
                                    }
                                    alt={manufacturerName}
                                    style={{
                                        height: 100,
                                        maxWidth: 'calc(100% - 30px)',
                                    }}
                                />
                            </LogoWrapper>
                            {!isManufacturerAvailable ? (
                                <section>
                                    <Row>
                                        <LoginContentColumn
                                            style={{textAlign: 'left'}}>
                                            The manufacturer you&apos;ve
                                            requested could not be found.
                                            <br />
                                            <br />
                                            <ol>
                                                <li>Check your URL</li>
                                                <li>
                                                    Try logging in at{' '}
                                                    <a href="https://cabinetry.online">
                                                        Cabinetry.Online
                                                    </a>
                                                </li>
                                                <li>
                                                    You believe this error is
                                                    incorrect, please contact
                                                    our{' '}
                                                    <a href="https://cabinetryonline.co/contact-us/">
                                                        Support Team
                                                    </a>
                                                    .
                                                </li>
                                            </ol>
                                        </LoginContentColumn>
                                    </Row>
                                </section>
                            ) : (
                                element
                            )}
                        </LoginContent>
                    </LoginWrapper>
                </AngleOverlay>
            </LoginBody>
        </ThemeProvider>
    );
};
