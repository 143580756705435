import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Formik, FormikHelpers} from 'formik';
import {Col, Row, Alert, Container, Spinner} from 'react-bootstrap';
import {FormControl, CBCRadio, BlueBold, CBCButton} from './StyledElements';
import {object, string} from 'yup';
import {useNavigate} from 'react-router-dom';
import {DisplayMessage, redirectUser} from 'shared/helpers/AuthHelpers';
import {Password} from 'shared';
import {useAppSelector} from 'store/customer';
import {
    LoginActionInterface,
    selectMessage,
} from 'components/customer/Auth/store/authSlice';
import {useLoginMutation} from 'components/customer/Auth/store/accountSlice';
import {LoginContentColumn} from 'components/customer/Auth/helper/layout';
import {
    selectManufacturerActive,
    selectPermitCustomerSelfRegistration,
    selectAllowDirectLogin,
} from 'store/customer/brandingSlice';
import NoCustomerLogin from 'components/customer/Auth/NoCustomerLogin';

export const Login = (): JSX.Element => {
    const navigate = useNavigate();
    const [loginError, setLoginError] = useState<string>();
    const [adminLogin, setAdminLogin] = useState<boolean>(false);
    const message = useAppSelector(selectMessage);

    const isManufacturerActive = useAppSelector(selectManufacturerActive);
    const allowsSelfRegistration = useAppSelector(
        selectPermitCustomerSelfRegistration
    );
    const allowsDirectLogin = useAppSelector(selectAllowDirectLogin);

    const [loginAction, {data, isLoading, isError, isSuccess}] =
        useLoginMutation();

    const LoginSchema = useMemo(
        () =>
            object().shape({
                username: string()
                    .email('You have entered invalid email address')
                    .required('Please enter your email'),
                password: string().required('Please enter the password'),
            }),
        []
    );

    const login = useCallback(
        (values: LoginActionInterface) => {
            void loginAction(values);
        },
        [loginAction]
    );

    const handleKeepLoggedIn = useCallback(
        (
                values: LoginActionInterface,
                setFieldValue: FormikHelpers<LoginActionInterface>['setFieldValue']
            ) =>
            () => {
                if (
                    values &&
                    setFieldValue &&
                    values.hasOwnProperty('keepLoggedIn')
                ) {
                    void setFieldValue('keepLoggedIn', !values.keepLoggedIn);
                }
            },
        []
    );

    const handleForgotPassword = useCallback(
        (username: string) => () => {
            navigate('/v2/reset-password', {
                state: {
                    username,
                },
            });
        },
        [navigate]
    );

    useEffect(() => {
        if (isError) {
            setLoginError('Incorrect username or password');

            return;
        }

        if (isSuccess && data) {
            if (data.accounts.length > 1) {
                navigate(
                    '/v2/accounts' +
                        (data.keepLoggedIn ? '?keepLoggedIn=1' : '')
                );
            } else {
                redirectUser(data.accounts[0]);
            }
        }
    }, [data, isError, isSuccess]);

    if (!allowsDirectLogin && !adminLogin) {
        return (
            <NoCustomerLogin
                setAdminLogin={setAdminLogin}
                withAdminLogin={false}
            />
        );
    }

    return (
        <Formik
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={LoginSchema}
            initialValues={{
                username: '',
                password: '',
                keepLoggedIn: true,
            }}
            onSubmit={login}>
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
                values,
                errors,
                touched,
            }) => (
                <form onSubmit={handleSubmit}>
                    <Container>
                        <Row>
                            <LoginContentColumn>
                                {message != null ? (
                                    <Alert
                                        style={{
                                            marginBottom: '15px',
                                            paddingLeft: '15px',
                                            paddingTop: '8px',
                                            paddingBottom: '8px',
                                            textAlign: 'left',
                                        }}
                                        variant="success">
                                        {message}
                                    </Alert>
                                ) : null}

                                {loginError != null ? (
                                    <Alert
                                        style={{
                                            marginBottom: '15px',
                                            paddingLeft: '15px',
                                            textAlign: 'left',
                                        }}
                                        variant="danger">
                                        {loginError}
                                    </Alert>
                                ) : null}

                                <DisplayMessage
                                    errors={errors}
                                    touched={touched}
                                />
                            </LoginContentColumn>
                        </Row>
                        <Row>
                            <LoginContentColumn>
                                <FormControl
                                    type="text"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values ? values.username : ''}
                                    name="username"
                                    placeholder="Email"
                                    autoComplete="username"
                                    $isInvalid={
                                        touched &&
                                        touched.username &&
                                        errors &&
                                        errors.username
                                            ? true
                                            : false
                                    }
                                />

                                <Password
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values ? values.password : ''}
                                    name="password"
                                    placeholder="Password"
                                    isInvalid={
                                        touched &&
                                        touched.password &&
                                        errors &&
                                        errors.password
                                            ? true
                                            : false
                                    }
                                    autoComplete="current-password"
                                />

                                <Row>
                                    <Col>
                                        <BlueBold
                                            flex
                                            $pointer
                                            onClick={handleKeepLoggedIn(
                                                values,
                                                setFieldValue
                                            )}>
                                            <CBCRadio
                                                checked={
                                                    values &&
                                                    values.hasOwnProperty(
                                                        'keepLoggedIn'
                                                    )
                                                        ? values.keepLoggedIn
                                                        : false
                                                }
                                            />{' '}
                                            Remember me
                                        </BlueBold>
                                    </Col>
                                    <Col>
                                        <BlueBold
                                            $pointer
                                            onClick={handleForgotPassword(
                                                values.username
                                            )}
                                            $align-right>
                                            Forgot password?
                                        </BlueBold>
                                    </Col>
                                </Row>

                                <Row
                                    style={{
                                        marginTop: '15px',
                                    }}>
                                    <Col md={{span: 8, offset: 2}}>
                                        <CBCButton
                                            type="submit"
                                            disabled={isLoading}>
                                            {isLoading ? (
                                                <Spinner
                                                    style={{
                                                        width: '25px',
                                                        height: '25px',
                                                    }}
                                                    animation="border"
                                                    role="status">
                                                    <span className="visually-hidden">
                                                        Loading...
                                                    </span>
                                                </Spinner>
                                            ) : (
                                                <>Sign In</>
                                            )}
                                        </CBCButton>
                                    </Col>
                                </Row>

                                {isManufacturerActive &&
                                allowsSelfRegistration ? (
                                    <div
                                        style={{
                                            marginTop: '30px',
                                        }}>
                                        <BlueBold inline as="span">
                                            Don&apos;t have an account?{' '}
                                        </BlueBold>
                                        <BlueBold
                                            inline
                                            underline
                                            $pointer
                                            as="a"
                                            href="/customerForm.php?newregister=true">
                                            Create one here
                                        </BlueBold>
                                    </div>
                                ) : null}
                            </LoginContentColumn>
                        </Row>
                    </Container>
                </form>
            )}
        </Formik>
    );
};
