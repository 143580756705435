import {Formik, FormikHelpers} from 'formik';
import React, {useMemo, useRef} from 'react';
import {Alert, Col, Container, Row, Spinner} from 'react-bootstrap';
import {getColoredImage} from 'shared/helpers';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';
import {CBCButton, CBCListItem, Title} from './StyledElements';
import {Link} from 'react-router-dom';
import {DisplayMessage} from 'shared/helpers/AuthHelpers';
import {Password} from 'shared';
import {SecurePassword} from 'assets';
import {LoginContentColumn} from 'components/customer/Auth/helper/layout';
import {getPasswordSchema} from 'components/customer/Auth/helper/passwordHelper';
import styled, {useTheme} from 'styled-components';

export interface SetPasswordInterface {
    password: string;
    confirmPassword: string;
    id?: string;
}

interface SetPasswordProps {
    initialValues: SetPasswordInterface;
    onSubmit: (
        values: SetPasswordInterface,
        actions?: FormikHelpers<SetPasswordInterface>
    ) => void;
    error: string;
    message: string;
    isLoading: boolean;
    isSuccess: boolean;
    userAccountSlot?: JSX.Element;
    headerTitle?: string;
    cancelButtonName?: string;
    submitButtonName?: string;
}

const StyledLiner = styled.p`
    text-align: left;
    margin: 0;
`;

const SetPasswordForm = ({
    initialValues,
    onSubmit,
    error,
    message,
    isLoading,
    isSuccess,
    userAccountSlot,
    headerTitle = 'Reset Password',
    submitButtonName = 'Update Password',
    cancelButtonName = 'Back to Login',
}: SetPasswordProps): JSX.Element => {
    const isSmallDevice = isDeviceSmall();

    const FormSchema = useRef(getPasswordSchema()).current;
    const theme = useTheme();
    const [passwordImage] = useMemo<string[]>(() => {
        const passwordImage = getColoredImage(
            String(SecurePassword),
            '#1392CD',
            theme.colors.primary.main
        );

        return [passwordImage];
    }, [theme]);

    return (
        <Formik
            validationSchema={FormSchema}
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={onSubmit}>
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                errors,
                touched,
            }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        {!!values.id ? (
                            <input name="id" value={values.id} type="hidden" />
                        ) : null}
                        <Container>
                            <Row
                                style={{
                                    fontSize: '.9em',
                                }}>
                                <LoginContentColumn>
                                    <Title style={{marginTop: 0}}>
                                        <img
                                            src={passwordImage}
                                            style={{marginRight: '15px'}}
                                        />
                                        {headerTitle}
                                    </Title>
                                </LoginContentColumn>
                            </Row>
                            <Row
                                style={{
                                    fontSize: '.9em',
                                }}>
                                <LoginContentColumn>
                                    <StyledLiner>
                                        Password <strong>must be</strong>{' '}
                                        minimum 8 characters long and include at
                                        least:
                                    </StyledLiner>
                                    <ul style={{padding: '0 10px'}}>
                                        <CBCListItem as="li">
                                            1 upper case letter
                                        </CBCListItem>
                                        <CBCListItem as="li">
                                            1 lower case letter
                                        </CBCListItem>
                                        <CBCListItem as="li">
                                            1 number
                                        </CBCListItem>
                                    </ul>
                                </LoginContentColumn>
                            </Row>
                            <Row>
                                <LoginContentColumn>
                                    <DisplayMessage
                                        errors={errors}
                                        touched={touched}
                                    />

                                    {error != null ? (
                                        <Alert
                                            style={{
                                                marginBottom: '15px',
                                                paddingLeft: '15px',
                                                textAlign: 'left',
                                            }}
                                            variant="danger">
                                            {error}
                                        </Alert>
                                    ) : null}

                                    {message != null ? (
                                        <Alert
                                            style={{
                                                textAlign: 'left',
                                                marginBottom: '15px',
                                                paddingLeft: '15px',
                                            }}
                                            variant="success">
                                            {message}
                                        </Alert>
                                    ) : null}
                                </LoginContentColumn>
                            </Row>
                            <Row>
                                <LoginContentColumn>
                                    {userAccountSlot}
                                    <Password
                                        disabled={error !== null || isSuccess}
                                        id="password"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.password}
                                        name="password"
                                        placeholder="Password"
                                        isInvalid={
                                            touched &&
                                            touched.password &&
                                            errors &&
                                            errors.password
                                                ? true
                                                : false
                                        }
                                    />

                                    <Password
                                        disabled={error !== null || isSuccess}
                                        id="confirmPassword"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.confirmPassword}
                                        name="confirmPassword"
                                        placeholder="Confirm Password"
                                        isInvalid={
                                            touched &&
                                            touched.confirmPassword &&
                                            errors &&
                                            errors.confirmPassword
                                                ? true
                                                : false
                                        }
                                    />

                                    <Row
                                        style={{
                                            marginTop: isSmallDevice
                                                ? '15px'
                                                : '35px',
                                        }}>
                                        <Col
                                            md={{span: 6, order: 1}}
                                            xs={{span: 12, order: 2}}>
                                            <CBCButton as={Link} to="/v2/login">
                                                {cancelButtonName}
                                            </CBCButton>
                                        </Col>
                                        <Col
                                            md={{span: 6, order: 2}}
                                            xs={{span: 12, order: 1}}>
                                            <CBCButton
                                                type="submit"
                                                disabled={
                                                    isLoading ||
                                                    error !== null ||
                                                    isSuccess
                                                }>
                                                {isLoading ? (
                                                    <Spinner
                                                        style={{
                                                            width: '25px',
                                                            height: '25px',
                                                        }}
                                                        animation="border"
                                                        role="status">
                                                        <span className="visually-hidden">
                                                            Loading...
                                                        </span>
                                                    </Spinner>
                                                ) : (
                                                    submitButtonName
                                                )}
                                            </CBCButton>
                                        </Col>
                                    </Row>
                                </LoginContentColumn>
                            </Row>
                        </Container>
                    </form>
                );
            }}
        </Formik>
    );
};

export default SetPasswordForm;
