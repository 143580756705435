import React, {memo, useEffect, useMemo, useRef, useState} from 'react';
import styled from 'styled-components';
import {FixedSizeGrid} from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import {Material} from 'components/customer/AdvancedMaterials/Materials/Material';
import {useAppDispatch, useAppSelector} from 'store/customer';
import {
    Loader,
    currentPageSet,
    materialColumnsSet,
    maxIndexSeenSet,
    selectLoading,
    selectTotalRecords,
} from 'components/customer/AdvancedMaterials/store/materialSlice';
import NoData from 'components/customer/AdvancedMaterials/Materials/NoData';
import {shallowEqual} from 'react-redux';
import {Spinner} from 'react-bootstrap';
import {GridPaginator} from 'components/customer/AdvancedMaterials/Materials/GridPaginator';
import {isMobile, isTablet} from 'react-device-detect';

const MIN_WIDTH = 180;

export const Materials = memo(() => {
    const dispatch = useAppDispatch();
    const loader = useAppSelector(selectLoading, shallowEqual);
    const totalRecords = useAppSelector(selectTotalRecords, shallowEqual);

    const gridReference = useRef();
    const containerReference = useRef<HTMLDivElement>();

    const [preferredColumns, setPreferredColumns] = useState(5);

    const {rows, columns} = useMemo(() => {
        let columns = preferredColumns;

        if (isMobile && !isTablet) {
            columns = 3;
        }

        if (totalRecords >= 0) {
            return {
                rows: Math.ceil(totalRecords / columns),
                columns,
            };
        }

        return {
            rows: 0,
            columns,
        };
    }, [totalRecords, preferredColumns, isMobile, isTablet]);

    useEffect(() => {
        if (containerReference.current) {
            const widthObserver = new ResizeObserver(
                (entries: ResizeObserverEntry[]) => {
                    if (entries.length > 0 && totalRecords > 0) {
                        const {contentRect} = entries[0];

                        setPreferredColumns(
                            Math.floor(contentRect.width / MIN_WIDTH)
                        );
                    }
                }
            );

            widthObserver.observe(containerReference.current);

            return () => {
                widthObserver.disconnect();
            };
        }
    }, [totalRecords]);

    useEffect(() => {
        dispatch(materialColumnsSet(columns));
    }, [columns]);

    useEffect(() => {
        return () => {
            dispatch(currentPageSet(1));
            dispatch(maxIndexSeenSet(0));
        };
    }, []);

    if (loader != Loader.LOADING_INITIAL && totalRecords == 0) {
        return <NoData />;
    }

    return (
        <Container ref={containerReference}>
            <AutoSizer>
                {({height, width}: {height: number; width: number}) => {
                    return (
                        <GridPaginator
                            totalRecords={totalRecords}
                            ref={gridReference.current}>
                            {(onItemsRendered) => {
                                return (
                                    <FixedSizeGrid
                                        ref={gridReference}
                                        columnCount={columns}
                                        columnWidth={(width - 10) / columns}
                                        height={height}
                                        width={width}
                                        rowHeight={165}
                                        rowCount={rows}
                                        onItemsRendered={onItemsRendered}>
                                        {Material}
                                    </FixedSizeGrid>
                                );
                            }}
                        </GridPaginator>
                    );
                }}
            </AutoSizer>

            {loader == Loader.LOADING_DATA ||
            loader == Loader.LOADING_INITIAL ? (
                <PageLoader>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </PageLoader>
            ) : null}
        </Container>
    );
});
Materials.displayName = 'Materials';

const Container = styled.div`
    flex: 1;
    position: relative;
    margin-top: 5px;

    > div > div:not(.spinner-border) {
        padding-bottom: 150px;
    }
`;

const PageLoader = styled.div`
    display: flex;
    position: absolute;
    inset: 0;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.6);
    justify-content: center;
    align-items: center;
`;
