import React, {useEffect} from 'react';
import {Form, InputGroup} from 'react-bootstrap';
import {Icon} from 'shared/helpers';
import {useJobContext} from 'contexts';
import styled from 'styled-components';
import {PartialJob} from 'shared/types/PartialJob';
import {useProductQuantity} from 'components/customer/JobDashboard/helpers/useProductQuantity';

type ProductQuantityType = {
    value: number;
    productDetails: {
        id: number;
    };
};

export const ProductQuantity = ({
    value,
    productDetails,
}: ProductQuantityType) => {
    const {
        quantity,
        decreaseQuantity,
        increaseQuantity,
        updateQuantity,
        updateProduct,
    } = useProductQuantity(value, productDetails);
    const {isPageDirty, setIsPageDirty, job} = useJobContext() as PartialJob;

    useEffect(() => {
        if (value != quantity) {
            // Add product details in dirty ref
            const index = isPageDirty.findIndex(
                (e) => e.id === productDetails.id
            );
            if (index == -1) {
                setIsPageDirty([
                    ...isPageDirty,
                    {id: productDetails.id, dirty: true},
                ]);
            }
        } else {
            isPageDirty.length &&
                setIsPageDirty(
                    isPageDirty.filter(function (item) {
                        return item.id !== productDetails.id;
                    })
                );
        }
    }, [quantity]);

    return (
        <NumberSpinner>
            <Icon
                iconName="Button-Minus.svg"
                style={{opacity: quantity == 1 ? 0.5 : 1}}
                onClick={decreaseQuantity}
            />
            <Form.Control
                type="number"
                name="product"
                value={typeof quantity === 'undefined' ? '' : quantity}
                onChange={updateQuantity}
                style={{textAlign: 'center'}}
            />
            <Icon iconName="Button-Plus.svg" onClick={increaseQuantity} />
            <Icon
                disabled={
                    typeof quantity === 'undefined' ||
                    value == quantity ||
                    job.status == 5
                }
                iconName="Button-Tick-Secondary.svg"
                onClick={updateProduct}
            />
        </NumberSpinner>
    );
};

const NumberSpinner = styled(InputGroup)`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 5px;

    input {
        border-radius: 8px !important;
        height: 25px;
        font-size: 1em;
        font-weight: bold;
        padding: 0;
        width: 20px;
    }

    img {
        width: 15px;
        cursor: pointer;
    }
`;
