import {useJobContext} from 'contexts';
import {useEffect, useMemo} from 'react';
import {PartialJob} from 'shared/types/PartialJob';
import {useLazyListBenchesQuery} from 'components/customer/BTM/store/btmApi';
import {formatPrice} from 'shared/helpers';
import {BenchtopType} from 'components/customer/BTM/entity/BenchtopType';
import {BenchtopMaterialType} from 'components/customer/BTM/entity/BenchtopMaterialType';
import {BenchtopMaterial} from '../entity/BenchtopMaterial';

export interface BenchTableInterface {
    id: number;
    jobBenchtopNumber: string;
    name: string;
    dimension: string;
    cost: string;
    variationCost: number;
    variationName: string;
    isOld: boolean;
    shape?: BenchtopType;
    btdShape?: string;
    type?: BenchtopMaterialType;
    externalBenchId?: number;
    quantity?: number;
    comment?: string;
    material?: BenchtopMaterial;
}

export const useFetchBenchesData = () => {
    const {job, room} = useJobContext() as PartialJob;

    const [fetchBenchtops, {data: benchtops}] = useLazyListBenchesQuery();

    useEffect(() => {
        if (job && job.id) {
            void fetchBenchtops({jobId: job.id}, true);
        }
    }, [job]);

    const benches = useMemo<BenchTableInterface[]>(() => {
        if (!job || !job.id) {
            return [];
        }

        const benches: BenchTableInterface[] = [];

        if (benchtops && benchtops.data) {
            benchtops.data.forEach((bench) => {
                benches.push({
                    isOld: false,
                    id: bench.id,
                    jobBenchtopNumber: bench.job_benchtop_number,
                    name: `${bench.shape.name} Bench`,
                    dimension: bench.dimension,
                    cost: formatPrice(bench.cost, {}),
                    variationCost: bench.variation_cost,
                    variationName: bench.variation?.name || '',
                    shape: bench.shape,
                    type: bench.type,
                    quantity: bench.quantity,
                    comment: bench.comment,
                    material: bench.material,
                });
            });
        }

        if (
            job.rooms &&
            room?.jobBenchtops &&
            Array.isArray(room.jobBenchtops)
        ) {
            const rawRoomNumber = job.rooms.findIndex((r) => room.id == r.id);
            const roomNumber = rawRoomNumber == -1 ? 1 : rawRoomNumber + 1;

            room.jobBenchtops.forEach((bench) => {
                benches.push({
                    isOld: true,
                    id: bench.id,
                    jobBenchtopNumber: `${roomNumber}-B${benches.length + 1}`,
                    name: `${bench.shape} Bench`,
                    dimension: bench.dimensionString,
                    cost: formatPrice(bench.cost, {}),
                    variationCost: bench.variation_cost,
                    variationName: bench.variation?.name || '',
                    externalBenchId: bench.externalBenchId,
                    btdShape: bench.shape,
                });
            });
        }

        return benches;
    }, [job, room, benchtops]);

    return benches;
};
