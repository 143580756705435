import React, {useCallback, useEffect} from 'react';
import {Button, FormControl, InputGroup} from 'react-bootstrap';
import {Icon} from 'shared/helpers';
import styled from 'styled-components';
import {useSearchText} from 'components/customer/AdvancedMaterials/helpers/useSearchText';
import {useDebouncedCallback} from 'use-debounce';

export const Search = () => {
    const [text, setText] = React.useState('');
    const {
        searchType,
        placeholder,
        searchText,
        selectedMenu,
        setSearchText,
        clearSearchText,
    } = useSearchText();

    const handleTextChange = useCallback(() => {
        setSearchText(text);

        // note: add dispatchers for door and edge menu
    }, [text, selectedMenu]);

    const debouncedHandleTextChange = useDebouncedCallback(
        handleTextChange,
        1000
    );

    const handleChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setText(event.target.value);

            debouncedHandleTextChange();
        },
        []
    );

    const handleOnKeyUp = useCallback(
        (event: React.KeyboardEvent) => {
            if (event.key === 'Enter') {
                handleTextChange();
            }
        },
        [text]
    );

    const clear = useCallback(() => {
        if (searchText != '') {
            clearSearchText();
            return;
        }

        if (text != '') {
            setText('');
        }
    }, [text, searchText]);

    useEffect(() => {
        if (searchText !== text && searchText == '') {
            setText('');
        }
    }, [searchText]);

    useEffect(() => {
        setText(searchText);
    }, [searchType]);

    return (
        <SearchField>
            <FormControl
                placeholder={placeholder}
                value={text}
                onChange={handleChange}
                onKeyUp={handleOnKeyUp}
            />
            {text.length > 0 ? (
                <ClearButton>
                    <Icon
                        onClick={clear}
                        iconName="Button-Cancel-Transparent.svg"
                    />
                </ClearButton>
            ) : null}
            <Button onClick={handleTextChange}>
                <Icon iconName="search-grey.svg" />
            </Button>
        </SearchField>
    );
};

const SearchField = styled(InputGroup)`
    width: 15vw;
    margin-left: 15px;
    height: 38px;

    // Mobile devices
    @media screen and (max-width: 768px) and (orientation: portrait),
        screen and (max-width: 1024px) and (orientation: landscape) {
        width: 40vw;
    }

    > input {
        border: 2px solid rgb(var(--primary_colour)) !important;
        border-right: 0 !important;
        border-radius: 8px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    > button {
        padding: 0;
        width: 35px;
        background: white !important;
        border: 2px solid rgb(var(--primary_colour)) !important;
        border-left: 0 !important;
        border-radius: 8px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
`;

const ClearButton = styled(InputGroup.Text)`
    border-top: 2px solid rgb(var(--primary_colour));
    border-bottom: 2px solid rgb(var(--primary_colour));
    padding-top: 4px;
    padding-left: 0;
    padding-right: 0;
    background: white;

    > img {
        width: 25px;
        height: 25px;
        cursor: pointer;
    }
`;
