import {useCallback} from 'react';

export const useItemHandler = () => {
    const addItemRef = useCallback(
        (index: number, items: HTMLDivElement[]) =>
            (element: HTMLDivElement) => {
                items[index ? index : 0] = element;
            },
        []
    );
    const handleKeyDown = useCallback(
        (
                index: number,
                onKeyDown: (event: React.KeyboardEvent, index: number) => void
            ) =>
            (event: React.KeyboardEvent) => {
                onKeyDown(event, index);
            },
        []
    );
    return {
        addItemRef,
        handleKeyDown,
    };
};
