/**
 *
 * Main application component.
 *
 * User Interface component hierarchy is managed from this component.
 *
 */
import React from 'react';
import {
    UserCheck,
    ManufacturerCheck,
    UserMessages,
    Messages,
    useNumberInputScrollDisabler,
    ErrorBoundary,
} from 'shared';
import {ContextManager} from 'contexts';
import {Toaster} from 'react-hot-toast';
import {urlPartsJob} from 'shared/helpers';
import {Updates} from 'shared/components/Updates';
import {Outlet} from 'react-router-dom';

export const App = () => {
    const data = urlPartsJob();

    useNumberInputScrollDisabler();

    return (
        <ContextManager>
            <ManufacturerCheck>
                <UserCheck>
                    <Updates />
                    <Toaster
                        containerStyle={{
                            bottom: '30px',
                            right: '30px',
                        }}
                    />
                    <UserMessages />
                    <Messages />
                    <section
                        className={
                            data.length
                                ? 'job-page mainContainer'
                                : 'mainContainer'
                        }>
                        <ErrorBoundary>
                            <Outlet />
                        </ErrorBoundary>
                    </section>
                </UserCheck>
            </ManufacturerCheck>
        </ContextManager>
    );
};
