import React, {MouseEvent, useCallback} from 'react';
import {JobCabinetVariation} from 'components/customer/Job/entity/JobCabinetVariation';
import {useJobContext} from 'contexts';
import {PartialJob} from 'shared/types/PartialJob';
import {
    ContentContainer,
    CustomButton,
    FlexedDiv,
    Label,
    NameContainer,
    NameTextContainer,
    TD,
    TableRow,
    VARIATION_TYPE,
} from './JobPendingVariation';
import {formatPrice} from 'shared/helpers';

interface ProductVariationsProps {
    variations: JobCabinetVariation[];
    removedJobCabinetIds: number[];
    handleRemove: (id: number, type: number) => void;
    handleUndo: (id: number, type: number) => void;
}

export const ProductVariations = ({
    variations,
    removedJobCabinetIds,
    handleRemove,
    handleUndo,
}: ProductVariationsProps) => {
    const {job} = useJobContext() as PartialJob;

    const handleRemoveCabinet = useCallback(
        (event: MouseEvent<HTMLButtonElement>) => {
            const id = parseInt(event.currentTarget.value);
            handleRemove(id, VARIATION_TYPE.CABINETS);
        },
        [handleRemove]
    );

    const handleUndoCabinet = useCallback(
        (event: MouseEvent<HTMLButtonElement>) => {
            const id = parseInt(event.currentTarget.value);
            handleUndo(id, VARIATION_TYPE.CABINETS);
        },
        [handleUndo]
    );

    if (variations) {
        return job?.rooms?.map((room, roomIndex) => {
            const roomVariations = variations.filter(
                (variation) => variation.room_id == room.id
            );

            return roomVariations
                .filter((item) => item.variation_cost > 0 || item.comment)
                .map((item, itemIndex) => {
                    const isRemoved = removedJobCabinetIds.includes(item.id);

                    const key = `${roomIndex}-${itemIndex}`;
                    return (
                        <TableRow key={key} isRemoved={isRemoved}>
                            <TD>
                                <NameContainer>
                                    <NameTextContainer>
                                        {`${roomIndex + 1}-${
                                            item.room_cab_number
                                        }`}{' '}
                                        {item.type_name}
                                    </NameTextContainer>
                                </NameContainer>
                            </TD>
                            <TD>
                                <FlexedDiv>
                                    <ContentContainer>
                                        <Label>{item.comment}</Label>
                                    </ContentContainer>
                                </FlexedDiv>
                            </TD>
                            <TD>{formatPrice(item.variation_cost, {})}</TD>
                            <TD>
                                {!isRemoved && (
                                    <CustomButton
                                        type="button"
                                        iconName="Options-Delete.svg"
                                        className="job-button button-light"
                                        onClick={handleRemoveCabinet}
                                        value={item.id}>
                                        Delete
                                    </CustomButton>
                                )}
                                {isRemoved ? (
                                    <CustomButton
                                        type="button"
                                        iconName="Button-Rotate-Anti-Clockwise.svg"
                                        className="job-button button-light"
                                        onClick={handleUndoCabinet}
                                        value={item.id}>
                                        Undo
                                    </CustomButton>
                                ) : null}
                            </TD>
                        </TableRow>
                    );
                });
        });
    }
};
