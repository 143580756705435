import React, {useState, useCallback, useRef} from 'react';
import {Button, Overlay, Tooltip} from 'react-bootstrap';
import {Icon} from 'shared/helpers';
import styled from 'styled-components';
import {useQFPContext} from 'components/customer/QFPRedux/QFPContext';
import {CopyQuantity} from 'components/customer/QFPRedux/CopyQuantity';

export const CopyAction = () => {
    const {fieldIndex} = useQFPContext();
    const [show, setShow] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const target = useRef<HTMLButtonElement>(null);

    const copyHandler = useCallback((e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        e.preventDefault();

        setShow((show) => !show);
    }, []);

    const hidePopover = useCallback(() => setShow(false), []);

    const mouseOver = useCallback(() => setShowTooltip(true), []);
    const mouseOut = useCallback(() => setShowTooltip(false), []);

    return (
        <CopyButton>
            <Button
                ref={target}
                title="Copy Product"
                variant="link"
                onClick={copyHandler}
                onMouseOver={mouseOver}
                onMouseOut={mouseOut}
                className="copy-qfp">
                <Icon iconName="Options-Copy.svg" />
            </Button>

            <Overlay
                placement="left"
                show={showTooltip}
                target={target.current}>
                {(props) => <Tooltip {...props}>Copy Product</Tooltip>}
            </Overlay>

            <Overlay
                show={show}
                target={target.current}
                placement="bottom"
                rootClose={true}
                onHide={hidePopover}>
                {(props) => (
                    <CustomTooltip id={`popover_${fieldIndex}`} {...props}>
                        <CopyQuantity index={fieldIndex} setShow={setShow} />
                    </CustomTooltip>
                )}
            </Overlay>
        </CopyButton>
    );
};

const CustomTooltip = styled(Tooltip)`
    > .tooltip-arrow {
        ::before {
            border-bottom-color: white;
        }
    }

    > .tooltip-inner {
        border-radius: 5px !important;
        background-color: white !important;
        box-shadow: 0px 1px 4px 0px #d7d7d7;
    }
`;

const CopyButton = styled.div`
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-right: 2px;
`;
