import React, {useCallback, useMemo, useRef, useState} from 'react';
import {InputGroup, Overlay, Popover} from 'react-bootstrap';
import styled from 'styled-components';
import {ColorSwatch} from 'components/customer/Materials/ColorSwatch';

const Preview = styled(InputGroup.Text)`
    align-self: center;
    padding-left: 5px;
    background: transparent;
    border: 0;
`;

const PreviewImage = styled.img<{$width: number}>`
    width: ${(props) => props.$width}px;
`;

interface InlinePreviewImageInterface {
    image: string;
    inlinePreviewImageBorder: boolean;
    showPopover?: boolean;
    popoverWidth?: number;
    height?: number;
    width?: number;
}

export const InlinePreviewImage = ({
    image,
    inlinePreviewImageBorder,
    height,
    width,
    showPopover = false,
    popoverWidth = 200,
}: InlinePreviewImageInterface) => {
    const [show, setShow] = useState(false);
    const [target, setTarget] = useState<HTMLElement>(null);
    const ref = useRef<HTMLElement>(null);

    const popover = useMemo(() => {
        if (showPopover) {
            return (
                <Overlay
                    transition={false}
                    show={show}
                    target={target}
                    placement="auto"
                    flip={true}
                    containerPadding={20}>
                    <Popover id="popover-contained">
                        <Popover.Body>
                            <PreviewImage src={image} $width={popoverWidth} />
                        </Popover.Body>
                    </Popover>
                </Overlay>
            );
        }

        return null;
    }, [showPopover, show, target]);

    const handleMouseOver = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            if (showPopover) {
                setShow(true);
                if (event.target instanceof HTMLElement)
                    setTarget(event.target);
            }
        },
        [showPopover]
    );

    const handleMouseOut = useCallback(
        () => showPopover && setShow(false),
        [showPopover]
    );

    return (
        <Preview ref={ref}>
            <ColorSwatch
                height={height}
                width={width}
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
                src={image}
                inlinePreviewImageBorder={inlinePreviewImageBorder}
            />
            {popover}
        </Preview>
    );
};
